import { List } from '@mui/material';
import filterService from 'services/filterService/filterService';
import { IMaterial, IMaterialOnMap, IOffMapAmounts } from '../interfaces';
import { Material } from './Material';

const materialIsOnMap = (
  material: IMaterial | IMaterialOnMap,
  isEdit: boolean,
): material is IMaterialOnMap => {
  material; // to suppress error

  return !isEdit;
};

const getKey = (material: IMaterial | IMaterialOnMap, isEdit: boolean) => {
  if (materialIsOnMap(material, isEdit)) {
    return material.iconId;
  } else {
    return material.materialInputId;
  }
};

interface IProps {
  isEdit: boolean;
  filter: string;
  materialsList: IMaterial[] | IMaterialOnMap[];
  selectedAmount: IOffMapAmounts;
  setSelectedAmount: React.Dispatch<React.SetStateAction<IOffMapAmounts>>;
  setPreviewClassName: React.Dispatch<React.SetStateAction<string>>;
  markersInView: boolean[];
}

export const ScrollingMaterials = ({
  isEdit,
  filter,
  materialsList,
  selectedAmount,
  setSelectedAmount,
  setPreviewClassName,
  markersInView,
}: IProps): JSX.Element => {
  return (
    <>
      <List
        dense
        sx={{
          padding: 0,
          paddingLeft: '3px',
          paddingRight: '3px',
          width: '100%',
        }}
      >
        {materialsList
          .filter(
            (mat, index) =>
              filterService([mat.nickname, mat.locationDescription], filter) &&
              (isEdit ? true : markersInView[index]),
          )
          .map((material, index) => (
            <Material
              key={getKey(material, isEdit)}
              index={index}
              isEdit={isEdit}
              setPreviewClassName={setPreviewClassName}
              material={material}
              setSelectedAmount={setSelectedAmount}
              selectedAmount={selectedAmount}
            />
          ))}
      </List>
    </>
  );
};
