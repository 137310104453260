import { Grid } from '@mui/material';
import {
  CharacteristicsEntity,
  CharacteristicValuesEntity,
} from '@afleya/material-schemas';
import { AutocompleteCharacteristic } from './AutocompleteCharacterisitic';
import { Characteristic } from '../CharacteristicType';

interface Props {
  listCharacteristicNames: string[];
  characteristics: CharacteristicsEntity[];
  characteristicValues: CharacteristicValuesEntity[];
  tempCharacteristics: Characteristic[];
  handleCharacteristicChange: (
    characteristicId: string,
    values: string[],
  ) => void;
  inputCharacteristicInputs?: Characteristic[];
}

export const RenderAutocompleteCharacteristics = ({
  listCharacteristicNames,
  characteristicValues,
  characteristics,
  tempCharacteristics,
  handleCharacteristicChange,
  inputCharacteristicInputs,
}: Props): JSX.Element => {
  return (
    <Grid container spacing={3}>
      {characteristics
        ?.filter(characteristic =>
          listCharacteristicNames.includes(characteristic.name),
        )
        .map(characteristic => (
          <Grid item xs={6} key={characteristic.id}>
            <AutocompleteCharacteristic
              characteristicValues={
                characteristicValues?.filter(
                  value => value.characteristicId === characteristic.id,
                ) ?? []
              }
              initialValues={
                tempCharacteristics
                  ?.find(item => item.characteristicId === characteristic.id)
                  ?.characteristicValues.map(value => ({ name: value })) ?? []
              }
              label={characteristic.name}
              onChange={newValues =>
                handleCharacteristicChange(
                  characteristic.id,
                  newValues.map(value => value.name),
                )
              }
              inputCharacteristicInputs={
                inputCharacteristicInputs?.find(
                  item => item.characteristicId === characteristic.id,
                )?.characteristicValues ?? []
              }
            />
          </Grid>
        ))}
    </Grid>
  );
};
