import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  placeholder: string;
  setCharacteristicFilter: (newCharacteristicFilter: boolean) => void;
  characteristicFilter: boolean;
}

export const CharacteristicFilter = ({
  placeholder,
  setCharacteristicFilter,
  characteristicFilter,
}: Props): JSX.Element => {
  const handleClick = () => {
    setCharacteristicFilter(!characteristicFilter);
  };

  return (
    <Button
      fullWidth
      variant={characteristicFilter ? 'contained' : 'outlined'}
      onClick={handleClick}
      sx={{
        width: '100%',
        height: '100%',
        fontSize: '0.6rem',
      }}
    >
      <FormattedMessage id={placeholder} />
    </Button>
  );
};
