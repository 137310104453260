import { useAsyncFn } from 'react-use';
import {
  BuildingPartFormAnswers,
  buildingPartFormAnswersSchema,
} from 'services/buildingPart/validationSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import PageFooter from 'components/PageFooter';
import { CircularProgress, Typography } from '@mui/material';
import { FooterButton, FormContainer } from 'components/Layout';
import { PageContent } from 'components/PageContent';
import { FormattedMessage, useIntl } from 'react-intl';
import { NameField } from 'pages/InputModules/InputModuleCreateBuildingPart/components/CreateBuildingPartForm/Fields';
import { DuplicateBuildingPartSelector } from 'pages/InputModules/InputModuleDuplicateBuildingPart/components';
import { useState } from 'react';
import { BuildingPartEntity } from '@afleya/project-schemas';
import { formatLocalBuildingPart } from 'services/buildingPart/formatLocalBuildingPart';
import { createLocalBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/createLocalBuildingPart';
import { duplicateBuildingPart } from 'services/offline/requests/buildingAndBuildingPart/duplicateBuildingPart';
import { deleteBuildingPartContent } from 'services/offline/requests/buildingAndBuildingPart/deleteBuildingPartContent';
import { getLastBuildingPartFloorLevel } from 'services/offline/requests/buildingAndBuildingPart/getLastBuildingPartFloorLevel';
import { DuplicateNewBuildingPartSelector } from './DuplicateNewBuildingPartSelector';

interface Props {
  projectId: string;
  buildingPart: BuildingPartEntity | undefined;
  motherBuildingParts: BuildingPartEntity[];
  targetBuildingId: string;
  setTargetBuildingPartsId: (targetBuildingPartsId: string[]) => void;
  targetBuildingPartsId: string[];
  setDuplicateState: (duplicateState: boolean) => void;
}

export const DuplicateMotherBuildingPartForm = ({
  projectId,
  buildingPart,
  motherBuildingParts,
  targetBuildingId,
  setTargetBuildingPartsId,
  targetBuildingPartsId,
  setDuplicateState,
}: Props): JSX.Element => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<BuildingPartFormAnswers>({
    mode: 'onChange',
    resolver: zodResolver(buildingPartFormAnswersSchema),
    defaultValues: {
      motherBuildingPartId: undefined,
      buildingPartName: ' ',
    },
  });
  const intl = useIntl();
  const [createNewBuildingPart, setCreateNewBuildingPart] =
    useState<boolean>(false);
  const [{ loading: submitLoading }, onSubmit] = useAsyncFn(
    async (formAnswers: BuildingPartFormAnswers) => {
      if (createNewBuildingPart) {
        const lastFloorLevel =
          (await getLastBuildingPartFloorLevel(
            targetBuildingId,
            formAnswers.motherBuildingPartId ?? null,
          )) ?? -1;
        const localBuildingPartToCreate = formatLocalBuildingPart({
          buildingPartName: formAnswers.buildingPartName,
          motherBuildingPartId: formAnswers.motherBuildingPartId ?? null,
          buildingId: targetBuildingId,
          floorLevel: lastFloorLevel + 1,
        });

        await createLocalBuildingPart(localBuildingPartToCreate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        targetBuildingPartsId = [localBuildingPartToCreate.id];
      }

      await Promise.all(
        targetBuildingPartsId.map(async targetBuildingPartId => {
          await deleteBuildingPartContent(projectId, targetBuildingPartId);
          await duplicateBuildingPart({
            projectId,
            sourceBuildingPartId: buildingPart?.id ?? '',
            targetBuildingId,
            targetBuildingPartId,
          });
        }),
      );
      setDuplicateState(true);
    },
    [
      createNewBuildingPart,
      targetBuildingId,
      targetBuildingPartsId,
      setDuplicateState,
      deleteBuildingPartContent,
      duplicateBuildingPart,
    ],
  );

  return (
    <>
      <PageContent>
        <FormContainer
          id="duplicate-motherBuildingPart-form"
          noValidate
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="h2" sx={{ marginTop: 3 }}>
            {intl.formatMessage({
              id: 'input-module-duplicate-mother-building-part.createNewBuildingPart',
            })}
          </Typography>
          <DuplicateNewBuildingPartSelector
            createNewBuildingPart={createNewBuildingPart}
            setCreateNewBuildingPart={setCreateNewBuildingPart}
            setValue={setValue}
            buildingPartName={buildingPart?.buildingPartName ?? ' '}
          />
          {createNewBuildingPart ? (
            <>
              <Typography variant="h2" sx={{ marginTop: 3 }}>
                {intl.formatMessage({
                  id: 'input-module-duplicate-mother-building-part.newBuildingPartName',
                })}
              </Typography>
              <NameField
                control={control}
                setValue={setValue}
                buildingPartName=""
                isMother={true}
              />
            </>
          ) : (
            <>
              <Typography variant="h2" sx={{ marginTop: 3 }}>
                {intl.formatMessage({
                  id: 'input-module-duplicate-mother-building-part.toBuildingParts',
                })}
              </Typography>
              <DuplicateBuildingPartSelector
                targetBuildingPartsId={targetBuildingPartsId}
                setTargetBuildingPartsId={setTargetBuildingPartsId}
                motherBuildingParts={motherBuildingParts
                  ?.filter(part => part.buildingId === targetBuildingId)
                  .filter(part => part.id !== buildingPart?.id)}
              />
            </>
          )}
        </FormContainer>
      </PageContent>
      <PageFooter>
        <FooterButton
          form="duplicate-motherBuildingPart-form"
          type="submit"
          color="primary"
          variant="contained"
          disabled={
            !isValid ||
            submitLoading ||
            (!createNewBuildingPart && targetBuildingPartsId.length === 0)
          }
        >
          {submitLoading ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage
              id={'input-module-duplicate-mother-building-part.submitButton'}
            />
          )}
        </FooterButton>
      </PageFooter>
    </>
  );
};
