import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { clearInputImageRecognition } from 'services/store/inputImageRecognition';
import { store } from 'store';

interface PredictionsProps {
  projectId: string;
  buildingId: string;
  buildingPartId: string;
  selectedPredictions: string[];
  handleSelectedPrediction: (prediction: string) => void;
}

export const PredictionsDisplay = ({
  projectId,
  buildingId,
  buildingPartId,
  selectedPredictions,
  handleSelectedPrediction,
}: PredictionsProps): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleNavigate = () => {
    store.dispatch(clearInputImageRecognition());
    navigate(
      routes.INPUT_MODULE_CATEGORIES.replace(':projectId', projectId)
        .replace(':buildingId', buildingId)
        .replace(':buildingPartId', buildingPartId),
    );
  };

  return (
    <FormControl>
      <FormLabel
        sx={{
          textAlign: 'left',
          marginBottom: '10px',
          fontSize: '16px',
          fontWeight: 'bold',
        }}
      >
        {intl.formatMessage({
          id: 'input-module-image-recognition-page.predictionTitle',
        })}
      </FormLabel>
      <RadioGroup
        name="predictions"
        defaultValue={selectedPredictions[0]}
        onChange={e => handleSelectedPrediction(e.target.value)}
      >
        {selectedPredictions.map(prediction => (
          <FormControlLabel
            key={prediction}
            label={prediction}
            value={prediction}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
      {selectedPredictions.length > 0 ? (
        <Button variant="contained" onClick={handleNavigate}>
          {intl.formatMessage({
            id: 'input-module-image-recognition-page.noResult',
          })}
        </Button>
      ) : (
        <Button variant="contained" onClick={handleNavigate}>
          {intl.formatMessage({
            id: 'input-module-image-recognition-page.badPrediction',
          })}
        </Button>
      )}
    </FormControl>
  );
};
