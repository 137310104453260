// schemas/requests/createAdminOrganization.ts
import { filterOrganizationTypes, OrganizationType } from "@afleya/common";
import {
  array,
  literal,
  nativeEnum,
  number,
  object,
  string,
  union
} from "zod";
var createAdminOrganizationSchema = object({
  name: string(),
  allowedProjectCreationCounter: number(),
  address: string().optional(),
  zipCode: string().optional(),
  city: string().optional(),
  email: union([literal(""), string().email()]).optional(),
  phone: string().optional(),
  siret: union([literal(""), string().regex(/^[0-9]{14}$/)]).optional(),
  types: string().array().transform((val) => filterOrganizationTypes(val)).pipe(array(nativeEnum(OrganizationType))),
  motherOrganizationId: string().uuid().nullable().optional()
});

// schemas/requests/createBuildingParts.ts
var createBuildingPartsSchema = {
  type: "object",
  properties: {
    floorsCount: { type: "number" },
    basementsCount: { type: "number" }
  },
  required: ["floorsCount", "basementsCount"],
  additionalProperties: false
};

// schemas/requests/createProject.ts
var createProjectSchema = {
  type: "object",
  properties: {
    projectOwner: { type: "string" },
    projectName: { type: "string" },
    address: { type: "string" },
    zipCode: { type: "string" },
    city: { type: "string" },
    estimatedStartDate: { type: ["string", "null"], format: "date" },
    estimatedEndDate: { type: ["string", "null"], format: "date" },
    latitude: { type: ["number", "null"] },
    longitude: { type: ["number", "null"] },
    projectOwnerId: { type: "string", format: "uuid" }
  },
  required: [
    "projectOwner",
    "projectName",
    "address",
    "zipCode",
    "city",
    "latitude",
    "longitude",
    "projectOwnerId"
  ],
  additionalProperties: false
};

// schemas/requests/createProjectBuildings.ts
var createProjectBuildingsSchema = {
  type: "object",
  properties: {
    buildingsCount: { type: "number" }
  },
  required: ["buildingsCount"],
  additionalProperties: false
};

// schemas/requests/createProjectOwnerOrganization.ts
import { siretSchema } from "@afleya/common";
import { literal as literal2, object as object2, string as string2, union as union2 } from "zod";
var createProjectOwnerOrganizationSchema = object2({
  name: string2().min(1),
  address: string2().optional(),
  zipCode: string2().optional(),
  city: string2().optional(),
  email: string2().email().optional(),
  phone: string2().optional(),
  siret: union2([literal2(""), siretSchema]).optional()
});

// schemas/requests/createRecyclingChannelAllocation.ts
var createRecyclingChannelAllocationSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    materialComponentId: { type: "string" },
    scenarioId: { type: "string" },
    recyclingChannelId: { type: "string" },
    percentage: { type: "number" },
    informations: {
      type: "array",
      items: { type: "string" }
    }
  },
  required: [
    "materialComponentId",
    "scenarioId",
    "recyclingChannelId",
    "percentage",
    "informations"
  ],
  additionalProperties: false
};

// schemas/requests/createReuseActorAllocation.ts
var createReuseActorAllocationSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    reuseChannelAllocationId: { type: "string" },
    reuseActorId: { type: "string" }
  },
  required: ["reuseChannelAllocationId", "reuseActorId"],
  additionalProperties: false
};

// schemas/requests/createReuseChannelAllocation.ts
var createReuseChannelAllocationSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    materialInputsGroupId: { type: "string" },
    scenarioId: { type: "string" },
    reuseChannelId: { type: "string" },
    percentage: { type: "number" },
    reuseConditions: { type: ["string", "null"] },
    availabilityDate: { type: ["string", "null"] },
    informations: {
      type: "array",
      items: { type: "string" }
    }
  },
  required: [
    "materialInputsGroupId",
    "scenarioId",
    "reuseChannelId",
    "percentage",
    "reuseConditions",
    "availabilityDate",
    "informations"
  ],
  additionalProperties: false
};

// schemas/requests/editAdminProject.ts
import { object as object3, string as string3 } from "zod";
var editAdminProjectSchema = object3({
  id: string3().uuid(),
  projectName: string3(),
  projectOwner: string3(),
  address: string3().optional(),
  zipCode: string3().optional(),
  city: string3().optional(),
  country: string3().optional(),
  organizationId: string3().uuid(),
  projectOwnerId: string3().uuid().nullable()
});

// schemas/requests/editAdminOrganization.ts
import { string as string4 } from "zod";
var editAdminOrganizationSchema = createAdminOrganizationSchema.extend(
  {
    id: string4().uuid()
  }
);

// schemas/requests/editBuilding.ts
import { BuildingTypology, OperationTypes } from "@afleya/common";
var editBuildingSchema = {
  type: "object",
  properties: {
    buildingName: { type: "string" },
    buildingYear: { type: ["string", "null"] },
    operationType: { enum: Object.values(OperationTypes) },
    typology: { enum: Object.values(BuildingTypology) },
    area: { type: ["number", "null"] }
  },
  additionalProperties: false
};

// schemas/requests/editBuildingPart.ts
var editBuildingPartSchema = {
  type: "object",
  properties: {
    buildingPartName: { type: "string" },
    motherBuildingPartId: { type: ["string", "null"] },
    buildingPartFloorLevel: { type: ["number", "null"] }
  },
  additionalProperties: false
};

// schemas/requests/editBuildingParts.ts
var editBuildingPartsSchema = {
  type: "array",
  items: {
    type: "object",
    properties: {
      buildingPartId: { type: "string" },
      buildingPartName: { type: "string" }
    },
    required: ["buildingPartId", "buildingPartName"],
    additionalProperties: false
  }
};

// schemas/requests/editCharacteristicInput.ts
var editCharacteristicInputSchema = {
  type: "object",
  properties: {
    characteristicId: { type: "string", format: "uuid" },
    characteristicValue: { type: "string" },
    materialInputId: { type: "string", format: "uuid" }
  },
  additionalProperties: false
};

// schemas/requests/editMaterialInput.ts
import { AgeTypes, InputMethod, InputUnit } from "@afleya/common";
var editMaterialInputSchema = {
  type: "object",
  properties: {
    buildingId: { type: "string" },
    buildingPartId: { type: "string" },
    dimensions: { type: "object" },
    locationDescription: { type: "string" },
    nickname: { type: "string" },
    amount: { type: "number" },
    comments: { type: ["string", "null"] },
    stateNewPercentage: { type: "number" },
    stateGoodPercentage: { type: "number" },
    stateMediumPercentage: { type: "number" },
    stateObsoletePercentage: { type: "number" },
    stateComments: { type: ["string", "null"] },
    age: { enum: Object.values(AgeTypes) },
    materialToDeduce: { type: "boolean" },
    assemblyTypeId: { type: ["string", "null"] },
    inputUnit: { enum: Object.values(InputUnit) },
    inputMethod: { enum: Object.values(InputMethod) },
    hasAsbestos: { type: "boolean" },
    hasLead: { type: "boolean" },
    hasOtherPollution: { type: "boolean" },
    reusePercentage: { type: "number" },
    weightPerFunctionalUnit: { type: "number" }
  }
};

// schemas/requests/editMaterialInputIcon.ts
var editMaterialInputIconEntitySchema = {
  type: "object",
  properties: {
    lat: { type: "number" },
    lng: { type: "number" },
    buildingPartMapId: { type: "string" },
    amount: { type: "number" }
  },
  additionalProperties: false
};

// schemas/requests/editScenario.ts
var editScenarioSchema = {
  type: "object",
  properties: {
    scenarioName: { type: "string" }
  },
  required: ["scenarioName"],
  additionalProperties: false
};

// schemas/requests/getAdminOrganization.ts
import { filterOrganizationTypes as filterOrganizationTypes2, OrganizationType as OrganizationType2 } from "@afleya/common";
import {
  array as array2,
  literal as literal3,
  nativeEnum as nativeEnum2,
  number as number2,
  object as object4,
  string as string5,
  union as union3
} from "zod";
var getAdminOrganizationSchema = object4({
  id: string5().uuid(),
  name: string5(),
  allowedProjectCreationCounter: number2(),
  address: string5(),
  zipCode: string5(),
  city: string5(),
  email: union3([literal3(""), string5().email()]),
  phone: string5(),
  // Add regex to check that siret is only numerical
  siret: union3([literal3(""), string5().regex(/^[0-9]{14}$/)]),
  types: string5().transform((val) => filterOrganizationTypes2(val.split(","))).pipe(array2(nativeEnum2(OrganizationType2))),
  motherOrganizationId: string5().uuid().nullable()
});

// schemas/requests/getAdminProject.ts
import { date, object as object5, string as string6 } from "zod";
var getAdminProjectSchema = object5({
  id: string6().uuid(),
  projectName: string6(),
  projectOwner: string6(),
  address: string6(),
  zipCode: string6(),
  city: string6(),
  country: string6(),
  organizationId: string6().uuid(),
  projectOwnerId: string6().uuid().nullable(),
  createdAt: date()
});

// schemas/requests/listAdminProjects.ts
var listAdminProjectsSchema = getAdminProjectSchema.array();

// schemas/requests/organizationInformations.ts
var organizationInformationsSchema = {
  type: "object",
  properties: {
    name: { type: "string" },
    address: { type: "string" },
    zipCode: { type: "string" },
    city: { type: "string" },
    email: { type: "string" },
    phone: { type: "string" },
    siret: { type: "string" }
  },
  required: ["name"],
  additionalProperties: false
};

// schemas/requests/projectUsersAccess.ts
var userAccessSchema = {
  type: "object",
  properties: {
    projectAccessId: { type: "string" },
    email: { type: "string" }
  },
  required: ["projectAccessId", "email"],
  additionalProperties: false
};
var projectUsersAccessSchema = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    projectName: { type: "string" },
    projectOwner: { type: "string" },
    address: { type: "string" },
    zipCode: { type: "string" },
    city: { type: "string" },
    users: {
      type: "array",
      items: userAccessSchema
    }
  },
  required: [
    "projectId",
    "projectName",
    "projectOwner",
    "address",
    "zipCode",
    "city",
    "users"
  ],
  additionalProperties: false
};

// schemas/requests/setBuildingConfiguration.ts
import { BuildingTypology as BuildingTypology2, OperationTypes as OperationTypes2 } from "@afleya/common";
import { nativeEnum as nativeEnum3, object as object6, string as string7, ZodIssueCode } from "zod";
var setBuildingConfigurationSchema = {
  type: "object",
  properties: {
    buildingName: { type: "string" },
    buildingYear: { type: "string" },
    operationType: { enum: Object.values(OperationTypes2) },
    typology: { enum: Object.values(BuildingTypology2) },
    area: { type: ["number", "null"] }
  },
  required: ["buildingName", "buildingYear", "operationType", "typology"],
  additionalProperties: false
};
var buildingConfigurationSchema = object6({
  buildingName: string7().min(1),
  buildingYear: string7().min(1),
  operationType: nativeEnum3(OperationTypes2),
  typology: nativeEnum3(BuildingTypology2),
  area: string7().optional()
}).superRefine((data, ctx) => {
  const areaNumber = Number(data.area);
  if (isNaN(areaNumber) || areaNumber < 0) {
    ctx.addIssue({
      path: ["area"],
      code: ZodIssueCode.custom
    });
  }
});

// schemas/requests/setMaterialInputGroupWasteCategory.ts
import { WasteCategory } from "@afleya/common";
var setMaterialInputGroupWasteCategorySchema = {
  type: "object",
  properties: {
    wasteCategory: { enum: Object.values(WasteCategory) }
  },
  required: ["wasteCategory"],
  additionalProperties: false
};

// schemas/requests/createCharacteristicInputsGroup.ts
var createCharacteristicInputsGroupSchema = {
  type: "object",
  properties: {
    id: { type: "string", format: "uuid" },
    characteristicId: { type: "string", format: "uuid" },
    characteristicValue: { type: "string" },
    materialInputsGroupId: { type: "string", format: "uuid" }
  },
  required: [
    "characteristicId",
    "characteristicValue",
    "materialInputsGroupId"
  ],
  additionalProperties: false
};

// schemas/entities/EnvironmentalIndicatorsEntity.ts
var rowCSRDIndicatorSchema = {
  type: "object",
  properties: {
    dnd: { type: "number" },
    dd: { type: "number" },
    total: { type: "number" }
  },
  required: ["dnd", "dd", "total"],
  additionalProperties: false
};
var environmentalIndicatorsSchema = {
  type: "object",
  properties: {
    totalWeight: { type: "number" },
    totalWeightRate: { type: ["number", "null"] },
    unlandfilledWasteWeightRate: { type: ["number", "null"] },
    unrecycledWasteWeight: { type: "number" },
    csrdTable: {
      type: "object",
      properties: {
        reuse: rowCSRDIndicatorSchema,
        recycling: rowCSRDIndicatorSchema,
        valorized: rowCSRDIndicatorSchema,
        totalNotEliminated: rowCSRDIndicatorSchema,
        totalEliminated: rowCSRDIndicatorSchema,
        total: rowCSRDIndicatorSchema
      },
      required: [
        "reuse",
        "recycling",
        "valorized",
        "totalNotEliminated",
        "totalEliminated",
        "total"
      ],
      additionalProperties: false
    }
  },
  required: [
    "totalWeight",
    "totalWeightRate",
    "unlandfilledWasteWeightRate",
    "unrecycledWasteWeight",
    "csrdTable"
  ],
  additionalProperties: false
};

// schemas/entities/MaterialInputsGroupPictureEntity.ts
import { object as object7, string as string8 } from "zod";
var materialInputsGroupPictureSchema = object7({
  materialInputId: string8().uuid(),
  pictureId: string8().uuid()
});

// schemas/entities/buildingEntity.ts
import { BuildingTypology as BuildingTypology3, OperationTypes as OperationTypes3 } from "@afleya/common";
var BuildingEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    buildingName: { type: "string" },
    buildingYear: { type: ["string", "null"] },
    projectId: { type: "string" },
    partsCount: { type: "number" },
    operationType: { enum: Object.values(OperationTypes3) },
    typology: { enum: Object.values(BuildingTypology3) },
    area: { type: ["number", "null"] }
  },
  required: [
    "id",
    "buildingName",
    "projectId",
    "partsCount",
    "operationType",
    "typology"
  ],
  additionalProperties: false
};

// schemas/entities/buildingNameEntity.ts
var BuildingNameEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    buildingName: { type: "string" }
  },
  required: ["id", "buildingName"],
  additionalProperties: false
};

// schemas/entities/buildingPartEntity.ts
import { BuildingPartType } from "@afleya/common";
var buildingPartEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    buildingId: { type: "string" },
    buildingPartName: { type: "string" },
    buildingPartType: { enum: Object.values(BuildingPartType) },
    buildingPartFloorLevel: { type: ["number", "null"] },
    motherBuildingPartId: { type: ["string", "null"] }
  },
  required: [
    "id",
    "buildingId",
    "buildingPartName",
    "buildingPartType",
    "buildingPartFloorLevel",
    "motherBuildingPartId"
  ],
  additionalProperties: false
};

// schemas/entities/buildingPartMapEntity.ts
var buildingPartMapEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    buildingPartId: { type: "string" }
  },
  required: ["id", "name", "buildingPartId"],
  additionalProperties: false
};

// schemas/entities/buildingPastOperationEntity.ts
import { OperationTypes as OperationTypes4 } from "@afleya/common";
var BuildingPastOperationSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    operationType: { enum: Object.values(OperationTypes4) },
    buildingId: { type: "string" }
  },
  required: ["id", "operationType", "buildingId"],
  additionalProperties: false
};

// schemas/entities/exportPireeCsvEntity.ts
var exportPireeCsvSchema = {
  type: "object",
  properties: {
    nickname: { type: "string" },
    amount: { type: "number" },
    weight: { type: "number" },
    location: { type: "string" },
    state: { type: "string" },
    stateComments: { type: "string" },
    comments: { type: "string" },
    assemblyType: { type: "string" },
    assemblyName: { type: "string" },
    functionalUnits: { type: "number" },
    weightPerFunctionalUnit: { type: "number" },
    unitMethod: { type: "string" },
    address: { type: "string" },
    startDate: { type: "string" },
    endDate: { type: "string" },
    idPassport: { type: "string" },
    tags: { type: "string" },
    putWarnings: { type: "string" },
    aspectColor: { type: "string" },
    massConversion: { type: "string" },
    photographLink: { type: "string" },
    isReusable: { type: ["string", "null"] },
    lengthInMm: { type: "number" },
    widthInMm: { type: "number" },
    heightInMm: { type: "number" }
  },
  required: [
    "nickname",
    "amount",
    "weight",
    "weightPerFunctionalUnit",
    "location",
    "state",
    "stateComments",
    "comments",
    "assemblyType",
    "assemblyName",
    "functionalUnits",
    "unitMethod",
    "address",
    "startDate",
    "endDate",
    "idPassport",
    "tags",
    "putWarnings",
    "aspectColor",
    "massConversion",
    "photographLink",
    "isReusable",
    "lengthInMm",
    "widthInMm",
    "heightInMm"
  ],
  additionalProperties: false
};

// schemas/entities/exportRenouveauCsvEntity.ts
var exportRenouveauCsvSchema = {
  type: "object",
  properties: {
    nickname: { type: "string" },
    amount: { type: "number" },
    dimensions: { type: "string" },
    weight: { type: "number" },
    state: { type: "string" },
    comments: { type: "string" },
    functionalUnits: { type: "number" },
    weightPerFunctionalUnit: { type: "number" },
    unitMethod: { type: "string" },
    lengthInM: { type: "number" },
    widthInM: { type: "number" },
    heightInM: { type: "number" },
    productCode: { type: "string" },
    category: { type: "string" },
    subCategory: { type: "string" },
    price: { type: "string" },
    location: { type: "string" }
  },
  required: [
    "nickname",
    "amount",
    "dimensions",
    "weight",
    "state",
    "comments",
    "functionalUnits",
    "weightPerFunctionalUnit",
    "unitMethod",
    "lengthInM",
    "widthInM",
    "heightInM",
    "productCode",
    "category",
    "subCategory",
    "price",
    "location"
  ],
  additionalProperties: false
};

// schemas/entities/exportSkopCsvEntity.ts
var exportSkopCsvSchema = {
  type: "object",
  properties: {
    nickname: { type: "string" },
    amount: { type: "number" },
    weight: { type: "number" },
    state: { type: "string" },
    functionalUnits: { type: "number" },
    comments: { type: "string" },
    assemblyType: { type: "string" },
    weightPerFunctionalUnit: { type: "number" },
    unitMethod: { type: "string" },
    buildingName: { type: "string" },
    buildingPartName: { type: "string" },
    locationDescription: { type: "string" },
    locationSkop: { type: "string" },
    component: { type: "string" },
    category: { type: "string" },
    startDate: { type: "string" },
    endDate: { type: "string" },
    lengthInCm: { type: "number" },
    widthInCm: { type: "number" },
    heightInCm: { type: "number" },
    putWarnings: { type: "string" },
    photoName: { type: "string" },
    price: { type: "string" },
    reusePotential: { type: "string" },
    materialInputId: { type: "string" }
  },
  required: [
    "nickname",
    "amount",
    "weight",
    "state",
    "functionalUnits",
    "comments",
    "assemblyType",
    "weightPerFunctionalUnit",
    "unitMethod",
    "buildingName",
    "buildingPartName",
    "locationDescription",
    "locationSkop",
    "component",
    "category",
    "startDate",
    "endDate",
    "lengthInCm",
    "widthInCm",
    "heightInCm",
    "putWarnings",
    "photoName",
    "price",
    "reusePotential",
    "materialInputId"
  ],
  additionalProperties: false
};

// schemas/entities/fileDownloadEntity.ts
var FileDownloadSchema = {
  type: "object",
  properties: {
    title: { type: "string" },
    content: { type: "string" }
  },
  required: ["title", "content"],
  additionalProperties: false
};

// schemas/entities/materialInputEntity.ts
import {
  AgeTypes as AgeTypes2,
  InputMethod as InputMethod2,
  InputUnit as InputUnit2,
  MaterialEvaluationMethod
} from "@afleya/common";
var materialInputEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    dimensions: { type: "object" },
    locationDescription: { type: "string" },
    nickname: { type: "string" },
    amount: { type: "number" },
    comments: { type: ["string", "null"] },
    buildingId: { type: "string" },
    buildingPartId: { type: "string" },
    projectId: { type: "string" },
    materialId: { type: "string" },
    materialCategoryId: { type: "string" },
    materialEvaluationMethod: { enum: Object.values(MaterialEvaluationMethod) },
    stateNewPercentage: { type: "number" },
    stateGoodPercentage: { type: "number" },
    stateMediumPercentage: { type: "number" },
    stateObsoletePercentage: { type: "number" },
    stateComments: { type: ["string", "null"] },
    age: { enum: Object.values(AgeTypes2) },
    assemblyTypeId: { type: ["string", "null"] },
    materialToDeduce: { type: "boolean" },
    inputUnit: { enum: Object.values(InputUnit2) },
    inputMethod: { enum: Object.values(InputMethod2) },
    hasAsbestos: { type: "boolean" },
    hasLead: { type: "boolean" },
    hasOtherPollution: { type: "boolean" },
    reusePercentage: { type: "number" },
    weightPerFunctionalUnit: { type: "number" }
  },
  required: [
    "id",
    "dimensions",
    "locationDescription",
    "nickname",
    "amount",
    "projectId",
    "buildingId",
    "buildingPartId",
    "materialId",
    "materialCategoryId",
    "materialEvaluationMethod",
    "stateNewPercentage",
    "stateGoodPercentage",
    "stateMediumPercentage",
    "stateObsoletePercentage",
    "age",
    "materialToDeduce",
    "inputUnit",
    "inputMethod",
    "reusePercentage"
  ],
  additionalProperties: false
};

// schemas/entities/materialInputEntityChore.ts
var materialInputEntityChoreSchema = {
  type: "object",
  properties: {
    ...materialInputEntitySchema.properties
  },
  required: [
    "id",
    "dimensions",
    "locationDescription",
    "nickname",
    "amount",
    "projectId",
    "buildingId",
    "buildingPartId",
    "materialId",
    "materialCategoryId",
    "materialEvaluationMethod",
    "stateNewPercentage",
    "stateGoodPercentage",
    "stateMediumPercentage",
    "stateObsoletePercentage",
    "age"
  ],
  additionalProperties: false
};

// schemas/entities/materialInputIconEntity.ts
var materialInputIconEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    lat: { type: "number" },
    lng: { type: "number" },
    materialInputId: { type: "string" },
    buildingPartMapId: { type: "string" },
    amount: { type: "number" }
  },
  required: [
    "id",
    "lat",
    "lng",
    "materialInputId",
    "buildingPartMapId",
    "amount"
  ],
  additionalProperties: false
};

// schemas/entities/materialInputPictureEntity.ts
var materialInputPictureEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    materialInputId: { type: "string" }
  },
  required: ["id", "name", "materialInputId"],
  additionalProperties: false
};

// schemas/entities/materialInputsCsvExportEntity.ts
var MaterialInputsCsvExportSchema = {
  type: "object",
  properties: {
    category: { type: "string" },
    material: { type: "string" },
    nickname: { type: "string" },
    dimensions: { type: "string" },
    amount: { type: ["number", "null"] },
    weight: { type: "number" },
    building: { type: "string" },
    buildingPart: { type: "string" },
    location: { type: "string" },
    stateNewPercentage: { type: ["number", "null"] },
    stateGoodPercentage: { type: ["number", "null"] },
    stateMediumPercentage: { type: ["number", "null"] },
    stateObsoletePercentage: { type: ["number", "null"] },
    stateComments: { type: ["string", "null"] },
    comments: { type: "string" },
    wasteCategory: { type: "string" },
    assemblyType: { type: ["string", "null"] },
    functionalUnits: { type: ["number", "null"] },
    unitMethod: { type: "string" },
    hasAsbestos: { type: "boolean" },
    hasLead: { type: "boolean" },
    hasOtherPollution: { type: "boolean" },
    reusePercentage: { type: ["number", "null"] }
  },
  required: [
    "category",
    "material",
    "nickname",
    "dimensions",
    "amount",
    "weight",
    "building",
    "buildingPart",
    "location",
    "stateNewPercentage",
    "stateGoodPercentage",
    "stateMediumPercentage",
    "stateObsoletePercentage",
    "stateComments",
    "comments",
    "wasteCategory",
    "assemblyType",
    "functionalUnits",
    "unitMethod",
    "hasAsbestos",
    "hasLead",
    "hasOtherPollution",
    "reusePercentage"
  ],
  additionalProperties: false
};

// schemas/entities/materialInputsGroupEntity.ts
import { WasteCategory as WasteCategory2 } from "@afleya/common";
var MaterialInputsGroupEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    nickname: { type: "string" },
    wasteCategory: { enum: Object.values(WasteCategory2) },
    amount: { type: "number" },
    weight: { type: "number" },
    functionalUnits: { type: "number" },
    materialId: { type: "string" },
    projectId: { type: "string" },
    stateNewPercentage: { type: "number" },
    stateGoodPercentage: { type: "number" },
    stateMediumPercentage: { type: "number" },
    stateObsoletePercentage: { type: "number" },
    needToBeComputed: { type: "boolean" },
    hasAsbestos: { type: "boolean" },
    hasLead: { type: "boolean" },
    hasOtherPollution: { type: "boolean" }
  },
  required: [
    "id",
    "nickname",
    "wasteCategory",
    "amount",
    "weight",
    "functionalUnits",
    "materialId",
    "projectId",
    "stateNewPercentage",
    "stateGoodPercentage",
    "stateMediumPercentage",
    "stateObsoletePercentage",
    "needToBeComputed",
    "hasAsbestos",
    "hasLead",
    "hasOtherPollution"
  ],
  additionalProperties: false
};

// schemas/entities/materialInputsGroupInfoEntity.ts
import { ListName, WasteCategory as WasteCategory3 } from "@afleya/common";
var reuseChannelAllocationInformationEntitySchema = {
  type: "object",
  properties: {
    listName: { enum: Object.values(ListName) },
    values: { type: "array", items: { type: "string" } }
  },
  required: ["listName", "values"],
  additionalProperties: false
};
var materialSheetInputSchema = {
  type: "object",
  properties: {
    amount: { type: "number" },
    dimensions: { type: "string" },
    location: { type: "string" },
    comments: { type: "string" },
    functionalUnits: { type: "number" },
    weight: { type: "number" },
    reusePercentage: { type: "number" }
  },
  required: [
    "amount",
    "dimensions",
    "location",
    "comments",
    "functionalUnits",
    "weight",
    "reusePercentage"
  ],
  additionalProperties: false
};
var materialInputsGroupInfoEntitySchema = {
  type: "object",
  properties: {
    projectName: { type: "string" },
    clientName: { type: "string" },
    projectCity: { type: "string" },
    materialName: { type: "string" },
    categoryName: { type: "string" },
    motherCategoryName: { type: "string" },
    defaultWasteCategory: { enum: Object.values(WasteCategory3) },
    nickname: { type: "string" },
    reuseChannelName: { type: "string" },
    inputs: {
      type: "array",
      items: materialSheetInputSchema
    },
    totalAmount: { type: "number" },
    totalFunctionalUnits: { type: "number" },
    totalWeight: { type: "number" },
    unit: { type: "string" },
    reusedPercentage: { type: "number" },
    reuseConditions: { type: "string" },
    availabilityDate: { type: "string" },
    reusedWeight: { type: "number" },
    stateNewPercentage: { type: "number" },
    stateGoodPercentage: { type: "number" },
    stateMediumPercentage: { type: "number" },
    stateObsoletePercentage: { type: "number" },
    informations: {
      type: "array",
      items: reuseChannelAllocationInformationEntitySchema
    },
    assemblyType: { type: "string" },
    materialInputPhotoUrls: { type: "array", items: { type: "string" } }
  },
  required: [
    "projectName",
    "clientName",
    "projectCity",
    "materialName",
    "categoryName",
    "motherCategoryName",
    "defaultWasteCategory",
    "nickname",
    "reuseChannelName",
    "totalAmount",
    "totalFunctionalUnits",
    "totalWeight",
    "unit",
    "reusedPercentage",
    "reuseConditions",
    "availabilityDate",
    "reusedWeight",
    "inputs",
    "stateNewPercentage",
    "stateGoodPercentage",
    "stateMediumPercentage",
    "stateObsoletePercentage",
    "informations",
    "assemblyType",
    "materialInputPhotoUrls"
  ],
  additionalProperties: false
};

// schemas/entities/materialInputsGroupPicturesIndexEntity.ts
var materialInputsGroupPicturesIndexSchema = {
  type: "object",
  properties: {
    projectName: { type: "string" },
    clientName: { type: "string" },
    projectCity: { type: "string" },
    materialName: { type: "string" },
    nickname: { type: "string" },
    locationPictures: {
      type: "array",
      items: {
        type: "object",
        properties: {
          location: { type: "string" },
          photoUrls: {
            type: "array",
            items: { type: "string" }
          }
        },
        required: ["location", "photoUrls"],
        additionalProperties: false
      }
    }
  },
  required: [
    "projectName",
    "clientName",
    "projectCity",
    "materialName",
    "nickname",
    "locationPictures"
  ],
  additionalProperties: false
};

// schemas/entities/materialInputsGroupWithNbPictureEntity.ts
import { WasteCategory as WasteCategory4 } from "@afleya/common";
import { boolean, nativeEnum as nativeEnum4, number as number3, object as object8, string as string9 } from "zod";
var materialInputsGroupWithNbPictureSchema = object8({
  id: string9(),
  nickname: string9(),
  wasteCategory: nativeEnum4(WasteCategory4),
  amount: number3(),
  weight: number3(),
  functionalUnits: number3(),
  materialId: string9(),
  projectId: string9(),
  stateNewPercentage: number3(),
  stateGoodPercentage: number3(),
  stateMediumPercentage: number3(),
  stateObsoletePercentage: number3(),
  nbPicture: number3(),
  hasAsbestos: boolean(),
  hasLead: boolean(),
  hasOtherPollution: boolean()
});
var materialInputsGroupWithNbPictureOutputSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    nickname: { type: "string" },
    wasteCategory: { enum: Object.values(WasteCategory4) },
    amount: { type: "number" },
    weight: { type: "number" },
    functionalUnits: { type: "number" },
    materialId: { type: "string" },
    projectId: { type: "string" },
    stateNewPercentage: { type: "number" },
    stateGoodPercentage: { type: "number" },
    stateMediumPercentage: { type: "number" },
    stateObsoletePercentage: { type: "number" },
    nbPicture: { type: "number" },
    hasAsbestos: { type: "boolean" },
    hasLead: { type: "boolean" },
    hasOtherPollution: { type: "boolean" }
  },
  required: [
    "id",
    "nickname",
    "wasteCategory",
    "amount",
    "weight",
    "functionalUnits",
    "materialId",
    "projectId",
    "stateNewPercentage",
    "stateGoodPercentage",
    "stateMediumPercentage",
    "stateObsoletePercentage",
    "nbPicture",
    "hasAsbestos",
    "hasLead",
    "hasOtherPollution"
  ],
  additionalProperties: false
};

// schemas/entities/organizationEntity.ts
import { OrganizationType as OrganizationType3 } from "@afleya/common";
var organizationEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    allowedProjectCreationCounter: { type: "number" },
    address: { type: "string" },
    zipCode: { type: "string" },
    city: { type: "string" },
    email: { type: "string" },
    phone: { type: "string" },
    siret: { type: "string" },
    types: { type: "array", items: { enum: Object.values(OrganizationType3) } }
  },
  required: [
    "id",
    "name",
    "allowedProjectCreationCounter",
    "address",
    "zipCode",
    "city",
    "email",
    "phone",
    "siret",
    "types"
  ],
  additionalProperties: false
};

// schemas/entities/organizationReportEntity.ts
import { DocumentType } from "@afleya/common";
var OrganizationReportEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    reportName: { type: "string" },
    reportUrl: { type: "string" },
    organizationId: { type: "string" },
    documentType: { enum: Object.values(DocumentType) },
    documentGenerator: { type: "string" }
  },
  required: [
    "id",
    "reportName",
    "reportUrl",
    "organizationId",
    "documentType",
    "documentGenerator"
  ],
  additionalProperties: false
};

// schemas/entities/projectAccessEntity.ts
var projectAccessSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    userName: { type: "string" },
    projectId: { type: "string" }
  },
  required: ["id", "userName", "projectId"],
  additionalProperties: false
};

// schemas/entities/projectCoordinatesEntity.ts
var projectCoordinatesSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    latitude: { type: "number" },
    longitude: { type: "number" }
  },
  required: ["id", "latitude", "longitude"],
  additionalProperties: false
};

// schemas/entities/projectEntity.ts
var ProjectStatus = /* @__PURE__ */ ((ProjectStatus2) => {
  ProjectStatus2["PROJECT_CREATED"] = "PROJECT_CREATED";
  ProjectStatus2["BUILDINGS_CREATED"] = "BUILDINGS_CREATED";
  return ProjectStatus2;
})(ProjectStatus || {});
var ProjectEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    projectOwner: { type: "string" },
    projectName: { type: "string" },
    address: { type: "string" },
    zipCode: { type: "string" },
    city: { type: "string" },
    country: { type: "string" },
    projectStatus: { enum: Object.values(ProjectStatus) },
    estimatedStartDate: { type: ["string", "null"], format: "date" },
    estimatedEndDate: { type: ["string", "null"], format: "date" },
    referenceScenarioId: { type: ["string", "null"], format: "uuid" },
    projectOwnerId: { type: ["string", "null"], format: "uuid" }
  },
  required: [
    "id",
    "projectOwner",
    "projectName",
    "address",
    "zipCode",
    "city",
    "country",
    "projectStatus",
    "estimatedStartDate",
    "estimatedEndDate",
    "referenceScenarioId",
    "projectOwnerId"
  ],
  additionalProperties: false
};

// schemas/entities/recyclingChannelAllocationEntity.ts
var recyclingChannelAllocationEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    percentage: { type: "number" },
    scenarioId: { type: "string" },
    recyclingChannelId: { type: "string" },
    materialComponentId: { type: "string" },
    informations: {
      type: "array",
      items: { type: "string" }
    }
  },
  required: [
    "id",
    "percentage",
    "scenarioId",
    "recyclingChannelId",
    "materialComponentId",
    "informations"
  ],
  additionalProperties: false
};

// schemas/entities/recyclingChannelAllocationInformationsEntity.ts
import { ListName as ListName2 } from "@afleya/common";
var recyclingChannelAllocationInformationsSchema = {
  type: "object",
  properties: {
    listName: { enum: Object.values(ListName2) },
    values: { type: "array", items: { type: "string" } }
  },
  required: ["listName", "values"],
  additionalProperties: false
};

// schemas/entities/recyclingChannelCsvExportEntity.ts
var RecyclingChannelCsvExportSchema = {
  type: "object",
  properties: {
    component: { type: "string" },
    recyclingChannel: { type: "string" },
    weight: { type: "number" },
    comments: { type: "string" }
  },
  required: ["component", "recyclingChannel", "weight", "comments"],
  additionalProperties: false
};

// schemas/entities/reuseChannelAllocationEntity.ts
var reuseChannelAllocationEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    materialInputsGroupId: { type: "string" },
    scenarioId: { type: "string" },
    reuseChannelId: { type: "string" },
    percentage: { type: "number" },
    reuseConditions: { type: ["string", "null"] },
    availabilityDate: { type: ["string", "null"] },
    informations: {
      type: "array",
      items: { type: "string" }
    }
  },
  required: [
    "id",
    "materialInputsGroupId",
    "scenarioId",
    "reuseChannelId",
    "percentage",
    "reuseConditions",
    "availabilityDate",
    "informations"
  ],
  additionalProperties: false
};

// schemas/entities/reuseChannelCsvExportEntity.ts
var ReuseChannelCsvExportSchema = {
  type: "object",
  properties: {
    material: { type: "string" },
    nickname: { type: "string" },
    reuseChannel: { type: "string" },
    amount: { type: "number" },
    weight: { type: "number" },
    percentage: { type: "number" },
    comments: { type: "string" }
  },
  required: [
    "material",
    "nickname",
    "reuseChannel",
    "amount",
    "weight",
    "percentage",
    "comments"
  ],
  additionalProperties: false
};

// schemas/entities/reuseWeightByWasteCategoryEntity.ts
import { WasteCategory as WasteCategory5 } from "@afleya/common";
var reuseWeightByWasteCategorySchema = {
  type: "object",
  properties: {
    wasteCategory: { enum: Object.values(WasteCategory5) },
    reuseWeight: { type: "number" }
  },
  required: ["wasteCategory", "reuseWeight"],
  additionalProperties: false
};

// schemas/entities/scenarioDocumentEntity.ts
import { DocumentStatus } from "@afleya/common";
var scenarioDocumentEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    scenarioDocumentName: { type: "string" },
    documentType: { type: "string" },
    isUploaded: { type: "boolean" },
    uploadKey: { type: "string" },
    status: { enum: Object.values(DocumentStatus) },
    completion: { type: ["number"] }
  },
  required: [
    "id",
    "scenarioDocumentName",
    "documentType",
    "isUploaded",
    "uploadKey",
    "status"
  ],
  additionalProperties: false
};

// schemas/entities/scenarioEntity.ts
var scenarioEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    scenarioName: { type: "string" },
    projectId: { type: "string" }
  },
  required: ["id", "scenarioName", "projectId"],
  additionalProperties: false
};

// schemas/entities/scenarioKPISEntity.ts
var scenarioKPISEntitySchema = {
  type: "object",
  properties: {
    projectId: { type: "string" },
    scenarioId: { type: "string" },
    costImpact: { type: "number" },
    co2Impact: { type: "number" },
    totalWeightWithoutDD: { type: "number" },
    totalWeight: { type: "number" },
    recyclingRate3R: { type: "number" },
    recyclingRateWithRefilling: { type: "number" },
    wasteCategoriesRepartition: {
      type: "object",
      properties: {
        DD: { type: "number" },
        DI: { type: "number" },
        DNIND: { type: "number" },
        DEEE: { type: "number" },
        DEA: { type: "number" }
      },
      required: ["DD", "DI", "DNIND", "DEEE", "DEA"],
      additionalProperties: false
    },
    disposalCategoriesRepartition: {
      type: "object",
      properties: {
        recycling: { type: "number" },
        reuse: { type: "number" },
        valorizedIncineration: { type: "number" },
        refilling: { type: "number" },
        other: { type: "number" }
      },
      required: [
        "recycling",
        "reuse",
        "valorizedIncineration",
        "refilling",
        "other"
      ],
      additionalProperties: false
    }
  },
  required: [
    "projectId",
    "scenarioId",
    "costImpact",
    "co2Impact",
    "totalWeightWithoutDD",
    "totalWeight",
    "recyclingRate3R",
    "recyclingRateWithRefilling",
    "wasteCategoriesRepartition",
    "disposalCategoriesRepartition"
  ],
  additionalProperties: false
};

// schemas/entities/scenarioPEMEntity.ts
var scenarioPEMEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    isProcessed: { type: "boolean" },
    materialInputsGroupId: { type: "string" }
  },
  required: ["isProcessed", "materialInputsGroupId"],
  additionalProperties: false
};

// schemas/entities/userEntity.ts
var UserRole = /* @__PURE__ */ ((UserRole2) => {
  UserRole2["USER"] = "user";
  UserRole2["ADMIN"] = "admin";
  UserRole2["PROJECT_OWNER"] = "projectOwner";
  return UserRole2;
})(UserRole || {});
var UserEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    email: { type: "string" },
    gcuAcceptedVersion: { type: "number" },
    organizationId: { type: ["string", "null"] },
    roles: {
      type: "array",
      items: {
        enum: Object.values(UserRole)
      }
    }
  },
  required: ["id", "email", "gcuAcceptedVersion", "organizationId", "roles"],
  additionalProperties: false
};

// schemas/entities/userProfile.ts
var userProfileSchema = {
  type: "object",
  properties: {
    email: { type: "string" },
    organization: { type: "string" },
    gcuAcceptedVersion: { type: "number" },
    roles: {
      type: "array",
      items: {
        enum: Object.values(UserRole)
      }
    },
    userId: { type: "string" }
  },
  required: ["email", "organization", "gcuAcceptedVersion", "roles", "userId"],
  additionalProperties: false
};

// schemas/entities/wasteByTreatmentCategoriesEntity.ts
var WasteCategoriesRepartitionSchema = {
  type: "object",
  properties: {
    total: { type: "number" },
    DD: { type: "number" },
    DI: { type: "number" },
    DNIND: { type: "number" },
    DEEE: { type: "number" },
    DEA: { type: "number" }
  },
  required: ["total", "DD", "DI", "DNIND", "DEEE", "DEA"],
  additionalProperties: false
};
var PemsByTreatmentCategoriesSchema = {
  type: "object",
  properties: {
    reuseWeights: WasteCategoriesRepartitionSchema,
    reutilisationWeights: WasteCategoriesRepartitionSchema,
    totalWeights: WasteCategoriesRepartitionSchema
  },
  required: ["reuseWeights", "reutilisationWeights", "totalWeights"],
  additionalProperties: false
};
var WastesByTreatmentCategoriesSchema = {
  type: "object",
  properties: {
    recyclingWeights: WasteCategoriesRepartitionSchema,
    valorizedIncinerationWeights: WasteCategoriesRepartitionSchema,
    refillingWeights: WasteCategoriesRepartitionSchema,
    disposalWeights: WasteCategoriesRepartitionSchema,
    totalWeights: WasteCategoriesRepartitionSchema
  },
  required: [
    "recyclingWeights",
    "valorizedIncinerationWeights",
    "refillingWeights",
    "disposalWeights",
    "totalWeights"
  ],
  additionalProperties: false
};
var PemAndWasteByTreatmentCategoriesSchema = {
  type: "object",
  properties: {
    pems: PemsByTreatmentCategoriesSchema,
    wastes: WastesByTreatmentCategoriesSchema
  },
  required: ["pems", "wastes"],
  additionalProperties: false
};

// schemas/entities/zipPicturesEntity.ts
var zipPicturesSchema = {
  type: "object",
  properties: {
    materialInputId: { type: "string" },
    materialInputNickname: { type: "string" },
    locationDescription: { type: "string" },
    materialInputPictureIds: {
      type: "array",
      items: { type: "string" }
    },
    buildingName: { type: "string" },
    buildingPartName: { type: "string" },
    motherBuildingPartName: { type: "string" }
  },
  required: [
    "materialInputId",
    "materialInputNickname",
    "locationDescription",
    "materialInputPictureIds",
    "buildingName",
    "buildingPartName",
    "motherBuildingPartName"
  ],
  additionalProperties: false
};

// schemas/entities/characteristicInputEntity.ts
var CharacteristicInputEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string", format: "uuid" },
    characteristicId: { type: "string", format: "uuid" },
    characteristicValue: { type: "string" },
    materialInputId: { type: "string", format: "uuid" }
  },
  required: [
    "id",
    "characteristicId",
    "characteristicValue",
    "materialInputId"
  ],
  additionalProperties: false
};

// schemas/entities/characteristicInputsGroupEntity.ts
var CharacteristicInputsGroupEntitySchema = {
  type: "object",
  properties: {
    characteristicId: { type: "string", format: "uuid" },
    characteristicValue: { type: "string" }
  },
  required: ["characteristicId", "characteristicValue"],
  additionalProperties: false
};
export {
  BuildingEntitySchema,
  BuildingNameEntitySchema,
  BuildingPartType,
  BuildingPastOperationSchema,
  CharacteristicInputEntitySchema,
  CharacteristicInputsGroupEntitySchema,
  FileDownloadSchema,
  MaterialInputsCsvExportSchema,
  MaterialInputsGroupEntitySchema,
  OrganizationReportEntitySchema,
  PemAndWasteByTreatmentCategoriesSchema,
  PemsByTreatmentCategoriesSchema,
  ProjectEntitySchema,
  ProjectStatus,
  RecyclingChannelCsvExportSchema,
  ReuseChannelCsvExportSchema,
  UserEntitySchema,
  UserRole,
  WasteCategoriesRepartitionSchema,
  WastesByTreatmentCategoriesSchema,
  buildingConfigurationSchema,
  buildingPartEntitySchema,
  buildingPartMapEntitySchema,
  createAdminOrganizationSchema,
  createBuildingPartsSchema,
  createCharacteristicInputsGroupSchema,
  createProjectBuildingsSchema,
  createProjectOwnerOrganizationSchema,
  createProjectSchema,
  createRecyclingChannelAllocationSchema,
  createReuseActorAllocationSchema,
  createReuseChannelAllocationSchema,
  editAdminOrganizationSchema,
  editAdminProjectSchema,
  editBuildingPartSchema,
  editBuildingPartsSchema,
  editBuildingSchema,
  editCharacteristicInputSchema,
  editMaterialInputIconEntitySchema,
  editMaterialInputSchema,
  editScenarioSchema,
  environmentalIndicatorsSchema,
  exportPireeCsvSchema,
  exportRenouveauCsvSchema,
  exportSkopCsvSchema,
  getAdminOrganizationSchema,
  getAdminProjectSchema,
  listAdminProjectsSchema,
  materialInputEntityChoreSchema,
  materialInputEntitySchema,
  materialInputIconEntitySchema,
  materialInputPictureEntitySchema,
  materialInputsGroupInfoEntitySchema,
  materialInputsGroupPictureSchema,
  materialInputsGroupPicturesIndexSchema,
  materialInputsGroupWithNbPictureOutputSchema,
  materialInputsGroupWithNbPictureSchema,
  organizationEntitySchema,
  organizationInformationsSchema,
  projectAccessSchema,
  projectCoordinatesSchema,
  projectUsersAccessSchema,
  recyclingChannelAllocationEntitySchema,
  recyclingChannelAllocationInformationsSchema,
  reuseChannelAllocationEntitySchema,
  reuseChannelAllocationInformationEntitySchema,
  reuseWeightByWasteCategorySchema,
  scenarioDocumentEntitySchema,
  scenarioEntitySchema,
  scenarioKPISEntitySchema,
  scenarioPEMEntitySchema,
  setBuildingConfigurationSchema,
  setMaterialInputGroupWasteCategorySchema,
  userAccessSchema,
  userProfileSchema,
  zipPicturesSchema
};
