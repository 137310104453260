import { sanitizeString, unifyString } from '@afleya/common';
import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { materialsInputsDatabase } from 'services/offline/dexieDB';
import isUndefined from 'lodash/isUndefined';
import { IndexableTypeArray } from 'dexie';

interface GetNicknamesProps {
  uniqueNickname: Set<string>;
  searchTerms: string;
  newSelectedDaughterCategories: MaterialCategoryEntity[];
  projectId: string;
  skip: number;
  take: number;
}

export const getNicknames = async ({
  uniqueNickname,
  searchTerms,
  newSelectedDaughterCategories,
  projectId,
  skip,
  take,
}: GetNicknamesProps): Promise<IndexableTypeArray> => {
  const unifiedSearchTerms = sanitizeString(searchTerms)
    .split(' ')
    .map(searchTerm => unifyString(searchTerm));
  const nicknames = await materialsInputsDatabase.materialInputs
    .orderBy('nickname')
    .filter(materialInput => {
      const nickname = materialInput.nickname;
      if (
        !uniqueNickname.has(nickname) &&
        unifiedSearchTerms.every(term =>
          unifyString(nickname).includes(term),
        ) &&
        newSelectedDaughterCategories.some(
          category => category.id === materialInput.materialCategoryId,
        ) &&
        !isUndefined(materialInput) &&
        materialInput.projectId === projectId
      ) {
        uniqueNickname.add(nickname);

        return true;
      }

      return false;
    })
    .offset(skip)
    .limit(take)
    .keys();

  return nicknames;
};
