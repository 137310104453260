/* eslint-disable complexity */
import { Grid } from '@mui/material';
import {
  CharacteristicsEntity,
  CharacteristicValuesEntity,
} from '@afleya/material-schemas';
import { ColorCharacteristic } from './ColorCharacteristic';
import { RenderAutocompleteCharacteristics } from './RenderAutocompleteCharacteristics';
import { Characteristic } from '../CharacteristicType';
import { CharacteristicDivider } from './Characteristic.style';
import { AnalysisModuleColorCharacteristic } from './AnalysisModuleColorCharacteristic';

interface Props {
  characteristics: CharacteristicsEntity[];
  characteristicValues: CharacteristicValuesEntity[];
  tempCharacteristics: Characteristic[];
  handleCharacteristicChange: (
    characteristicId: string,
    values: string[],
  ) => void;
  inputCharacteristicInputs?: Characteristic[];
}

export const CreateOrEditCharacteristicForm = ({
  characteristicValues,
  characteristics,
  tempCharacteristics,
  inputCharacteristicInputs,
  handleCharacteristicChange,
}: Props): JSX.Element => {
  const firstTypeofCharacteristics = ['Fabricant'];
  const SecondTypeofCharacteristics = [
    'Revêtement/Finition',
    'Type de fixation',
    'Détails des accessoires',
    'Performance',
    'Détails du modèle',
  ];

  const showedCharacteristics = [
    ...firstTypeofCharacteristics,
    ...SecondTypeofCharacteristics,
    'Couleur',
  ];

  const OtherCharacteristics =
    characteristics
      ?.filter(
        characteristic => !showedCharacteristics.includes(characteristic.name),
      )
      .map(characteristic => characteristic.name) ?? [];

  const colorCharacteristic = characteristics.find(
    item => item.name === 'Couleur',
  );

  return (
    <>
      <RenderAutocompleteCharacteristics
        listCharacteristicNames={firstTypeofCharacteristics}
        characteristics={characteristics}
        characteristicValues={characteristicValues}
        tempCharacteristics={tempCharacteristics}
        handleCharacteristicChange={handleCharacteristicChange}
        inputCharacteristicInputs={inputCharacteristicInputs}
      />
      <CharacteristicDivider />
      <Grid>
        {colorCharacteristic !== undefined &&
          inputCharacteristicInputs === undefined && (
            <ColorCharacteristic
              key={colorCharacteristic.id}
              initialColor={
                tempCharacteristics.find(
                  item => item.characteristicId === colorCharacteristic.id,
                )?.characteristicValues[0] ?? null
              }
              onChange={newColor =>
                handleCharacteristicChange(colorCharacteristic.id, [newColor])
              }
              label={colorCharacteristic.name}
            />
          )}
        {colorCharacteristic !== undefined && inputCharacteristicInputs && (
          <AnalysisModuleColorCharacteristic
            key={colorCharacteristic.id}
            initialColors={
              tempCharacteristics?.find(
                item => item.characteristicId === colorCharacteristic.id,
              )?.characteristicValues ?? []
            }
            onChange={newColor =>
              handleCharacteristicChange(colorCharacteristic.id, newColor)
            }
            label={colorCharacteristic.name}
            inputCharacteristicInputs={
              inputCharacteristicInputs?.find(
                item => item.characteristicId === colorCharacteristic.id,
              )?.characteristicValues ?? []
            }
          />
        )}
      </Grid>
      <CharacteristicDivider />
      <RenderAutocompleteCharacteristics
        listCharacteristicNames={SecondTypeofCharacteristics}
        characteristics={characteristics}
        characteristicValues={characteristicValues}
        tempCharacteristics={tempCharacteristics}
        handleCharacteristicChange={handleCharacteristicChange}
        inputCharacteristicInputs={inputCharacteristicInputs}
      />
      <CharacteristicDivider />
      <RenderAutocompleteCharacteristics
        listCharacteristicNames={OtherCharacteristics}
        characteristics={characteristics}
        characteristicValues={characteristicValues}
        tempCharacteristics={tempCharacteristics}
        handleCharacteristicChange={handleCharacteristicChange}
        inputCharacteristicInputs={inputCharacteristicInputs}
      />
    </>
  );
};
