import { sanitizeString, unifyString } from '@afleya/common';
import { MaterialCategoryEntity } from '@afleya/material-schemas';
import isUndefined from 'lodash/isUndefined';
import { materialsInputsDatabase } from 'services/offline/dexieDB';
import { IndexableTypeArray } from 'dexie';
import { LocalMaterialInput } from 'services/materialInputs/types';

interface getMaterialInputWithNicknameProps {
  uniqueMaterialInput: Set<string>;
  nicknamesToFetch: IndexableTypeArray;
  searchTerms: string;
  newSelectedDaughterCategories: MaterialCategoryEntity[];
  projectId: string;
}

export const getMaterialInputWithNickname = async ({
  uniqueMaterialInput,
  nicknamesToFetch,
  searchTerms,
  newSelectedDaughterCategories,
  projectId,
}: getMaterialInputWithNicknameProps): Promise<LocalMaterialInput[]> => {
  const unifiedSearchTerms = sanitizeString(searchTerms)
    .split(' ')
    .map(searchTerm => unifyString(searchTerm));
  unifiedSearchTerms.forEach(splitSearchTerm => unifyString(splitSearchTerm));
  const materialInputs = await materialsInputsDatabase.materialInputs
    .orderBy('nickname')
    .filter(materialInput => {
      const nickname = materialInput.nickname;
      const uniqueIdentifier = `${nickname}-${materialInput.materialId}`;
      if (
        !uniqueMaterialInput.has(uniqueIdentifier) &&
        nicknamesToFetch.includes(nickname) &&
        unifiedSearchTerms.every(term =>
          unifyString(materialInput.nickname).includes(term),
        ) &&
        newSelectedDaughterCategories.some(
          category => category.id === materialInput.materialCategoryId,
        ) &&
        !isUndefined(materialInput) &&
        materialInput.projectId === projectId
      ) {
        uniqueMaterialInput.add(uniqueIdentifier);

        return true;
      }

      return false;
    })
    .toArray();

  return materialInputs;
};
