import { sanitizeString, unifyString } from '@afleya/common';
import {
  MaterialCategoryEntity,
  MaterialEntity,
} from '@afleya/material-schemas';
import { materialsDatabase } from 'services/offline/dexieDB';
import { getNewSelectedDaughterCategories } from '../materialInput/getNewSelectedDaughterCategories';

interface Props {
  skip: number;
  take: number;
  searchTerms: string;
  selectedMotherCategories: MaterialCategoryEntity[];
  selectedDaughterCategories: MaterialCategoryEntity[];
  categories: MaterialCategoryEntity[];
}

export const listMaterialsWithProps = async ({
  skip,
  take,
  searchTerms,
  selectedMotherCategories,
  selectedDaughterCategories,
  categories,
}: Props): Promise<MaterialEntity[]> => {
  let materials: MaterialEntity[] = [];

  const unifiedSearchTerms = sanitizeString(searchTerms)
    .split(' ')
    .map(searchTerm => unifyString(searchTerm));

  const newSelectedDaughterCategories = getNewSelectedDaughterCategories(
    selectedMotherCategories,
    selectedDaughterCategories,
    categories,
  );

  materials = await materialsDatabase.materials
    .orderBy('name')
    .filter(material => {
      const unifiedName = unifyString(material.name);
      const unifiedTags =
        material.tags !== undefined ? unifyString(material.tags) : '';

      return (
        newSelectedDaughterCategories
          .map(category => category.id)
          .includes(material.categoryId) &&
        unifiedSearchTerms.every(
          term => unifiedName.includes(term) || unifiedTags.includes(term),
        )
      );
    })
    .offset(skip)
    .limit(take)
    .toArray();

  return materials;
};
