import {
  clearMaterialInputId,
  setMaterialInputId,
} from 'services/store/materialInputIdState';
import { store } from 'store';
import { routes } from 'routes';
import { NavigateFunction } from 'react-router-dom';

interface Props {
  materialInputId?: string;
  projectId: string;
  buildingId: string;
  buildingPartId: string;
  categoryId: string;
  materialId: string;
  navigate: NavigateFunction;
}

export const navigateToModuleInput = ({
  materialInputId,
  projectId,
  buildingId,
  buildingPartId,
  categoryId,
  materialId,
  navigate,
}: Props): void => {
  materialInputId !== undefined
    ? store.dispatch(setMaterialInputId(materialInputId))
    : store.dispatch(clearMaterialInputId());
  navigate(
    routes.INPUT_MODULE_INPUT.replace(':projectId', projectId)
      .replace(':buildingId', buildingId)
      .replace(':buildingPartId', buildingPartId)
      .replace(':categoryId', categoryId)
      .replace(':materialId', materialId),
  );
};
