import { LocalMaterialInput } from 'services/materialInputs/types';
import { MaterialCategoryEntity } from '@afleya/material-schemas';
import { getNewSelectedDaughterCategories } from './getNewSelectedDaughterCategories';
import { getNicknames } from './getNicknames';
import { getMaterialInputWithNickname } from './getMaterialInputWithNickname';

interface ListMaterialInputsResult {
  materialInputs: LocalMaterialInput[];
  newTake: number;
}

interface Props {
  projectId: string;
  skip: number;
  take: number;
  searchTerms: string;
  selectedMotherCategories: MaterialCategoryEntity[];
  selectedDaughterCategories: MaterialCategoryEntity[];
  categories: MaterialCategoryEntity[];
}

export const listMaterialInputsWithProps = async ({
  projectId,
  skip,
  take,
  searchTerms,
  selectedDaughterCategories,
  selectedMotherCategories,
  categories,
}: Props): Promise<ListMaterialInputsResult> => {
  const newSelectedDaughterCategories = getNewSelectedDaughterCategories(
    selectedMotherCategories,
    selectedDaughterCategories,
    categories,
  );

  const uniqueNickname: Set<string> = new Set();

  const nicknamesToFetch = await getNicknames({
    uniqueNickname,
    searchTerms,
    newSelectedDaughterCategories,
    projectId,
    skip,
    take,
  });

  const uniqueMaterialInput: Set<string> = new Set();

  const materialInputs = await getMaterialInputWithNickname({
    uniqueMaterialInput,
    nicknamesToFetch,
    searchTerms,
    newSelectedDaughterCategories,
    projectId,
  });

  const result = {
    materialInputs: materialInputs,
    newTake: nicknamesToFetch.length,
  };

  return result;
};
