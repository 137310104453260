import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import { PageContent } from 'components/PageContent';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, CircularProgress } from '@mui/material';
import { routes } from 'routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import { ConfirmDialog } from 'components/ConfirmDialog';
import BoxAlert from 'components/BoxAlert/BoxAlert';
import SuccessDrawer from 'components/SuccessDrawer';
import LoadingBox from 'components/LoadingBox';
import { listProjectBuildings } from 'services/offline/requests/buildingAndBuildingPart/listProjectBuildings';
import { FooterButton } from 'components/Layout';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import { duplicateBuildingContent } from 'services/offline/requests/buildingAndBuildingPart/duplicateBuildingContent';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { deleteBuildingContent } from 'services/offline/requests/buildingAndBuildingPart/deleteBuildingContent';
import {
  confirmDialogContentText,
  confirmDialogTitle,
} from './InputModuleDuplicateBuildingFunctions';
import DuplicateBuildingSelector from './DuplicateBuildingSelector';

// eslint-disable-next-line complexity
export const InputModuleDuplicateBuilding = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(beforeUnload, []);

  const project = useSelector(
    (state: RootState) => state.offlineProject.project,
  );

  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();

  const [targetBuildingId, setTargetBuildingId] = useState<string>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const {
    loading,
    error,
    value: buildings,
  } = useAsync(() => listProjectBuildings(projectId ?? ''), [projectId]);

  const buildingName =
    buildings?.find(building => building.id === buildingId)?.buildingName ?? '';

  const [
    { value: isBuildingDuplicated, loading: duplicatingBuilding },
    handleDuplicate,
  ] = useAsyncFn(async () => {
    setShowConfirmDialog(false);
    if (
      targetBuildingId !== undefined &&
      projectId !== undefined &&
      buildingId !== undefined
    ) {
      await deleteBuildingContent({
        projectId,
        buildingId: targetBuildingId,
      });
      await duplicateBuildingContent({
        projectId,
        sourceBuildingId: buildingId,
        targetBuildingId,
      });

      return true;
    } else {
      return false;
    }
  }, [projectId, buildingId, targetBuildingId]);

  const handleValidate = () => {
    setShowConfirmDialog(true);
  };

  const handleClose = () => {
    setShowConfirmDialog(false);
  };

  if (projectId === undefined || buildingId === undefined) {
    navigate(-1);

    return <></>;
  }

  return (
    <>
      <PageHeader
        title={intl.formatMessage(
          {
            id: 'input-module-duplicate-building.title',
          },
          {
            buildingName: buildingName,
          },
        )}
        subtitle={intl.formatMessage({
          id: 'input-module-duplicate-building.subtitle',
        })}
        locationTitle={intl.formatMessage(
          {
            id: 'input-module-duplicate-building.locationTitle',
          },
          {
            projectName: project?.projectName,
            buildingName: buildingName,
          },
        )}
        backLinkTitle={intl.formatMessage({
          id: 'input-module-duplicate-building.backlink',
        })}
        backLinkPath={routes.INPUT_MODULE_BUILDING_PARTS.replace(
          ':projectId',
          projectId,
        ).replace(':buildingId', buildingId)}
      />
      <PageContent>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <BoxAlert contentId="input-module-duplicate-building.loadingError" />
        ) : (
          <DuplicateBuildingSelector
            sourceBuildingId={buildingId}
            targetBuildingId={targetBuildingId}
            setTargetBuildingId={setTargetBuildingId}
            buildings={buildings}
          />
        )}
      </PageContent>
      <PageFooter>
        <FooterButton
          color="primary"
          variant="contained"
          disabled={
            targetBuildingId === undefined || duplicatingBuilding === true
          }
          onClick={handleValidate}
        >
          {duplicatingBuilding ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage id="input-module-duplicate-building.footerButton" />
          )}
        </FooterButton>
      </PageFooter>
      {showConfirmDialog && (
        <ConfirmDialog
          title={confirmDialogTitle(
            intl,
            'input-module-duplicate-building.title',
            buildings?.find(building => building.id === buildingId)
              ?.buildingName,
          )}
          contentText={confirmDialogContentText(
            intl,
            'input-module-duplicate-building.confirm',
            buildingName,
            buildings?.find(building => building.id === targetBuildingId)
              ?.buildingName,
          )}
          onAgree={() => {
            void handleDuplicate();
          }}
          onDisagree={handleClose}
        />
      )}
      <SuccessDrawer
        open={isBuildingDuplicated}
        title={intl.formatMessage({
          id: 'input-module-duplicate-building.successDuplicateBuilding',
        })}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            navigate(
              routes.INPUT_MODULE_BUILDING_PARTS.replace(
                ':projectId',
                projectId,
              ).replace(':buildingId', buildingId),
            );
          }}
        >
          <FormattedMessage id="input-module-duplicate-building.toBuildingParts" />
        </Button>
      </SuccessDrawer>
    </>
  );
};
