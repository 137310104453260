/* eslint-disable max-lines */
import {
  BuildingEntity,
  BuildingPartEntity,
  CharacteristicInputEntity,
} from '@afleya/project-schemas';
import { MaterialInputTable } from '@afleya/sync-protocol-schemas';
import Dexie, { Table } from 'dexie';
import { LocalBuilding } from 'services/building/types';
import { LocalBuildingPart } from 'services/buildingPart/types';
import { LocalMaterialInputIcon } from 'services/materialInputIcons/type';
import { LocalMaterialInput } from 'services/materialInputs/types';

import { LocalCharacteristicInput } from 'services/characteristicInput/types';
import { SyncableDexie } from './SyncableDexie';
import { updateSynchroStatus } from './updateSynchroStatus';

const baseApiUrl = process.env.REACT_APP_API_URL ?? '';

export class MaterialsInputsDatabase extends SyncableDexie {
  materialInputs!: Table<LocalMaterialInput>;
  materialInputIcons!: Table<LocalMaterialInputIcon>;
  buildings!: Table<BuildingEntity>;
  buildingParts!: Table<BuildingPartEntity>;
  characteristicInputs!: Table<CharacteristicInputEntity>;
  constructor() {
    super(MaterialInputTable.MaterialInput);

    this.version(19).stores({
      materialInputs:
        'id,materialId,materialCategoryId,projectId,buildingId,buildingPartId,nickname',
      materialInputIcons: 'id,materialInputId,buildingPartMapId',
      buildings:
        'id,buildingName,projectId,buildingYear,operationType,typology,area,partsCount',
      buildingParts:
        'id,buildingPartName,buildingPartType,buildingPartFloorLevel,buildingId',
      characteristicInputs: 'id,materialInputId',
    });

    this.syncable.on('statusChanged', newStatus => {
      console.log(
        'Material Inputs Sync Status changed: ' +
          Dexie.Syncable.StatusTexts[newStatus],
      );
      void updateSynchroStatus();
    });
  }

  startSync = (): Dexie.Promise<void> => {
    return this.syncable.connect('materialInputsSyncProtocol', baseApiUrl, {
      initialUpload: false,
    });
  };

  stopSync = (): Dexie.Promise<void> => {
    return this.syncable.disconnect(baseApiUrl);
  };

  addMaterialInput = async (
    localMaterialInput: LocalMaterialInput,
  ): Promise<void> => {
    await this.materialInputs.add(localMaterialInput);
  };

  deleteMaterialInput = async (materialInputId: string): Promise<void> => {
    await this.materialInputs.delete(materialInputId);
  };

  updateMaterialInput = async (
    materialInputToUpdateId: string,
    localMaterialInputUpdates: Partial<LocalMaterialInput>,
  ): Promise<void> => {
    await this.materialInputs.update(
      materialInputToUpdateId,
      localMaterialInputUpdates,
    );
  };

  bulkPutMaterialInputs = async (
    localMaterialInputs: LocalMaterialInput[],
  ): Promise<void> => {
    await this.materialInputs.bulkPut(localMaterialInputs);
  };

  addIcon = async (localIcon: LocalMaterialInputIcon): Promise<void> => {
    await this.materialInputIcons.add(localIcon);
  };

  deleteIcon = async (iconId: string): Promise<void> => {
    await this.materialInputIcons.delete(iconId);
  };

  updateIcon = async (
    iconToUpdateId: string,
    localMaterialInputIconUpdates: Partial<LocalMaterialInputIcon>,
  ): Promise<void> => {
    await this.materialInputIcons.update(
      iconToUpdateId,
      localMaterialInputIconUpdates,
    );
  };

  bulkPutMaterialInputIcons = async (
    localMaterialInputIcons: LocalMaterialInputIcon[],
  ): Promise<void> => {
    await this.materialInputIcons.bulkPut(localMaterialInputIcons);
  };

  deleteIconsOfMaterialInput = async (
    materialInputId: string,
  ): Promise<void> => {
    const iconsId = await this.listIconIdsFromMaterialInputId(materialInputId);
    await Promise.all(
      iconsId.map(async iconId => {
        await this.deleteIcon(iconId);
      }),
    );
  };

  deleteIconsOfMap = async (buildingPartMapId: string): Promise<void> => {
    const iconsId = await this.listIconIdsFromMapId(buildingPartMapId);
    await Promise.all(
      iconsId.map(async iconId => {
        await this.deleteIcon(iconId);
      }),
    );
  };

  listIconIdsFromMaterialInputId = async (
    materialInputId: string,
  ): Promise<string[]> => {
    const iconIds = (await this.materialInputIcons
      .where({ materialInputId })
      .primaryKeys()) as string[];

    return iconIds;
  };

  listIconIdsFromMapId = async (
    buildingPartMapId: string,
  ): Promise<string[]> => {
    const iconIds = (await this.materialInputIcons
      .where({ buildingPartMapId })
      .primaryKeys()) as string[];

    return iconIds;
  };

  deleteProjectBuildingParts = async (projectId: string): Promise<void> => {
    const buildings = await this.buildings
      .filter(building => building.projectId === projectId)
      .toArray();

    await Promise.all(
      buildings.map(
        async building =>
          await this.buildingParts.where({ buildingId: building.id }).delete(),
      ),
    );
  };

  deleteProjectBuildings = async (projectId: string): Promise<void> => {
    await this.buildings.where({ projectId }).delete();
  };

  deleteProjectBuildingsAndBuildingParts = async (
    projectId: string,
  ): Promise<void> => {
    await this.deleteProjectBuildingParts(projectId);
    await this.deleteProjectBuildings(projectId);
  };

  getBuilding = async (buildingId: string): Promise<BuildingEntity> => {
    const building = await this.buildings.get(buildingId);

    if (!building) {
      throw new Error('building not found');
    }

    return building;
  };

  findBuilding = async (
    buildingId: string,
  ): Promise<BuildingEntity | undefined> => {
    return await this.buildings.get(buildingId);
  };

  deleteBuilding = async (buildingId: string): Promise<void> => {
    await this.buildings.delete(buildingId);
  };

  getBuildingPart = async (
    buildingPartId: string,
  ): Promise<BuildingPartEntity> => {
    const buildingPart = (await this.buildingParts.get(
      buildingPartId,
    )) as BuildingPartEntity;

    return buildingPart;
  };

  addBuildingPart = async (
    localBuildingPart: LocalBuildingPart,
  ): Promise<void> => {
    await this.buildingParts.add(localBuildingPart);
  };

  deleteBuildingPart = async (buildingPartId: string): Promise<void> => {
    await this.buildingParts.delete(buildingPartId);
  };

  updateBuildingPart = async (
    buildingPartToUpdateId: string,
    localBuildingPartUpdates: Partial<LocalBuildingPart>,
  ): Promise<void> => {
    await this.buildingParts.update(
      buildingPartToUpdateId,
      localBuildingPartUpdates,
    );
  };

  bulkPutBuildings = async (localBuilding: LocalBuilding[]): Promise<void> => {
    await this.buildings.bulkPut(localBuilding);
  };

  bulkPutBuildingParts = async (
    localBuildingParts: LocalBuildingPart[],
  ): Promise<void> => {
    await this.buildingParts.bulkPut(localBuildingParts);
  };

  addCharacteristicInput = async (
    localCharacteristicInput: CharacteristicInputEntity,
  ): Promise<void> => {
    await this.characteristicInputs.add(localCharacteristicInput);
  };

  deleteCharacteristicInputsOfMaterialInput = async (
    materialInputId: string,
  ): Promise<void> => {
    await this.open();
    const characteristicsToDelete = (await this.characteristicInputs
      .where({ materialInputId })
      .primaryKeys()) as string[];

    await this.characteristicInputs.bulkDelete(characteristicsToDelete);
  };

  bulkPutCharacteristicInputs = async (
    localCharacteristicInputs: LocalCharacteristicInput[],
  ): Promise<void> => {
    await this.characteristicInputs.bulkPut(localCharacteristicInputs);
  };
}
