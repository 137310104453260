import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CharacteristicsEntity,
  CharacteristicValuesEntity,
} from '@afleya/material-schemas';
import { useState } from 'react';
import PageHeader from 'components/PageHeader';
import { updateCharacteristics } from 'services/networking/requests/traceability/updateCharacteristics';
import { CreateOrEditCharacteristicForm } from './components/CreateOrEditCharacteristicForm';
import { Characteristic } from './CharacteristicType';

interface Props {
  setCharacteristics: (characteristics: Characteristic[]) => void;
  setDisplayCreateCharacteristic: (display: boolean) => void;
  materialName: string;
  characteristicNames: CharacteristicsEntity[];
  characteristicValues: CharacteristicValuesEntity[];
  characteristics: Characteristic[];
}

export const CreateOrEditCharacteristic = ({
  setDisplayCreateCharacteristic,
  setCharacteristics,
  materialName,
  characteristicValues,
  characteristicNames,
  characteristics,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [tempCharacteristics, setTempCharacteristics] =
    useState(characteristics);

  const handleCharacteristicChange = (
    characteristicId: string,
    values: string[],
  ) => {
    setTempCharacteristics(prevState => {
      return updateCharacteristics(prevState, characteristicId, values);
    });
  };

  return (
    <Dialog fullScreen open={true}>
      <DialogTitle>
        <PageHeader
          title={intl.formatMessage(
            {
              id: 'characteristic-input-page.title',
            },
            {
              materialName: materialName,
            },
          )}
        />
        <Typography>
          <FormattedMessage id={'characteristic-input-page.subtitle'} />
        </Typography>
      </DialogTitle>
      <form>
        <DialogContent>
          <CreateOrEditCharacteristicForm
            characteristics={characteristicNames}
            characteristicValues={characteristicValues}
            tempCharacteristics={tempCharacteristics}
            handleCharacteristicChange={handleCharacteristicChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setDisplayCreateCharacteristic(false);
            }}
            style={{
              marginRight: '0.625em',
            }}
          >
            <FormattedMessage id="characteristic-input-page.cancelButton" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCharacteristics(tempCharacteristics);
              setDisplayCreateCharacteristic(false);
            }}
          >
            <FormattedMessage id="characteristic-input-page.submitButton" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
