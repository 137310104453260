import BoxAlert from 'components/BoxAlert';
import { FooterButton } from 'components/Layout';
import LoadingBox from 'components/LoadingBox';
import { PageContent } from 'components/PageContent';
import PageFooter from 'components/PageFooter';
import PageHeader from 'components/PageHeader';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync, useAsyncFn } from 'react-use';
import { routes } from 'routes';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getBuildingWithBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/getBuildingWithBuildingParts';
import { beforeUnload } from 'services/beforeUnload/beforeUnload';
import { Stack } from '@mui/material';
import { listMaterialInputsForRecap } from 'services/offline/materialInputsForRecap';
import { InputModuleSummaryButton } from 'components/InputModuleSummaryButton';
import { ListMotherBuildingParts } from './ListMotherBuildingParts';

export const InputModuleBuildingParts = (): JSX.Element => {
  const { projectId, buildingId } = useParams<{
    projectId: string;
    buildingId: string;
  }>();

  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(beforeUnload, []);

  const project = useSelector(
    (state: RootState) => state.offlineProject.project,
  );

  const [{ loading, error, value: buildingAndParts }, fetchBuildingParts] =
    useAsyncFn(
      () => getBuildingWithBuildingParts(buildingId ?? ''),
      [buildingId],
    );

  useAsync(fetchBuildingParts, [fetchBuildingParts]);

  const { value: nbMaterialInputs } = useAsync(
    async () =>
      (
        await listMaterialInputsForRecap({
          projectId: projectId ?? '',
          buildingId,
        })
      ).length,
    [projectId, buildingId],
  );

  const locationTitle = intl.formatMessage(
    {
      id: 'input-module-building-parts-page.locationTitle',
    },
    {
      projectName: project?.projectName,
      buildingName: buildingAndParts?.building.buildingName,
    },
  );

  const onClickInputModuleSummaryButton = () =>
    navigate(
      routes.INPUT_MODULE_BUILDING_PARTS_SUMMARY.replace(
        ':projectId',
        projectId ?? '',
      ).replace(':buildingId', buildingId ?? ''),
    );

  if (projectId === undefined || buildingId === undefined) {
    navigate(-1);

    return <></>;
  }

  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'input-module-building-parts-page.title',
        })}
        subtitle={intl.formatMessage({
          id: 'input-module-building-parts-page.subtitle',
        })}
        backLinkTitle={intl.formatMessage({
          id: 'input-module-home-page.title',
        })}
        backLinkPath={routes.INPUT_MODULE_HOME_PAGE.replace(
          ':projectId',
          projectId,
        )}
        locationTitle={locationTitle}
      />

      <PageContent>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <BoxAlert
            contentId={'input-module-building-parts-page.loadingError'}
          />
        ) : (
          (buildingAndParts?.buildingParts && (
            <ListMotherBuildingParts
              buildingId={buildingId}
              projectId={projectId}
              buildingParts={buildingAndParts.buildingParts}
              fetchBuildingParts={fetchBuildingParts}
            />
          )) ?? <></>
        )}
      </PageContent>
      <PageFooter>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          sx={{ width: 1, height: 1 }}
        >
          <InputModuleSummaryButton
            nbMaterialInputs={nbMaterialInputs ?? 0}
            onClick={onClickInputModuleSummaryButton}
          />
          <FooterButton
            variant="outlined"
            onClick={() =>
              navigate(
                routes.INPUT_MODULE_CREATE_BUILDING_PART.replace(
                  ':projectId',
                  projectId,
                ).replace(':buildingId', buildingId),
              )
            }
          >
            <FormattedMessage id="input-module-building-parts-page.button" />
          </FooterButton>
        </Stack>
        <FooterButton
          color="primary"
          variant="text"
          onClick={() =>
            navigate(
              routes.INPUT_MODULE_DUPLICATE_BUILDING.replace(
                ':projectId',
                projectId,
              ).replace(':buildingId', buildingId),
            )
          }
        >
          <FormattedMessage id="input-module-building-parts-page.duplicateBuilding" />
        </FooterButton>
      </PageFooter>
    </>
  );
};
